import { Wallet, walletToSubstrateExtensionMap } from 'lib/wallets';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useSubstrateExtension } from 'recoil/substrateExtension';
import { WalletInfo } from './interfaces';
import { WalletsPage } from './WalletsPage';

interface Props {
  onClose: VoidFunction;
}

export function SubstrateWallets({ onClose }: Props) {
  const { t } = useTranslation('common', {
    keyPrefix: 'walletSetup.substrate',
  });
  const { extensions, selectedExtension, setSelectedExtension } =
    useSubstrateExtension();

  const walletsInfo: WalletInfo[] = useMemo(() => {
    function checkIsAvailable(wallet: Wallet) {
      return extensions.includes(walletToSubstrateExtensionMap[wallet] ?? '');
    }

    return [
      {
        name: Wallet.Polkadot,
        isAvailable: checkIsAvailable(Wallet.Polkadot),
        isConnected:
          selectedExtension === walletToSubstrateExtensionMap[Wallet.Polkadot],
        primary: t('polkadot.primary'),
        secondary: t('polkadot.secondary'),
        link: {
          label: t('polkadot.link'),
          url: 'https://polkadot.js.org/extension/',
        },
      },
      {
        name: Wallet.Talisman,
        isAvailable: checkIsAvailable(Wallet.Talisman),
        isConnected:
          selectedExtension === walletToSubstrateExtensionMap[Wallet.Talisman],
        primary: t('talisman.primary'),
        secondary: t('talisman.secondary'),
        link: {
          label: t('talisman.link'),
          url: 'https://www.talisman.xyz',
        },
      },
      {
        name: Wallet.SubWallet,
        isAvailable: checkIsAvailable(Wallet.SubWallet),
        isConnected:
          selectedExtension === walletToSubstrateExtensionMap[Wallet.SubWallet],
        primary: t('subWallet.primary'),
        secondary: t('subWallet.secondary'),
        link: {
          label: t('subWallet.link'),
          url: 'https://subwallet.app',
        },
      },
    ];
  }, [t, extensions, selectedExtension]);

  return (
    <WalletsPage
      wallets={walletsInfo}
      onConnect={({ name }: WalletInfo) => {
        setSelectedExtension(walletToSubstrateExtensionMap[name]);
        onClose();
      }}
    />
  );
}
