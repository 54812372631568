import { Burger, useMantineTheme } from '@mantine/core';
import { useMobileNavbar } from 'recoil/mobileNavbar';

export const OpenMenuButton = () => {
  const theme = useMantineTheme();
  const { isOpen, toggle } = useMobileNavbar();

  return (
    <Burger
      opened={isOpen}
      onClick={toggle}
      size={'sm'}
      color={theme.colors.gray[6]}
      hiddenFrom={'sm'}
    />
  );
};
