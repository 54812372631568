import { ActionIcon, Box, Group, Modal, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'next-i18next';
import classes from './Buttons.module.css';

import { moonbeam, moonriver } from '@moonbeam-network/xcm-config';
import { CBSubscribeButton } from 'components/ConnectWallet/buttons/CBSubscribeButton';
import { useIsCBSubscribeDisabled } from 'hooks/flags/useIsCBSubscribeDisabled';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { TbMailPlus } from 'react-icons/tb';
import { capitalize } from 'utils/text';

const broadcastAddress = {
  [moonbeam.key]: '0xAE7b98A4CAa39B3F41011b6e2ECA61cC9191D274',
  [moonriver.key]: '0x8baf67e19248a33081670d8369834F72D7D87Ea0',
};

export const CBSubscribe = () => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const moonChain = useMoonChainKeyParam();
  const isCBSubscribeDisabled = useIsCBSubscribeDisabled();

  const partnerAddress = broadcastAddress[moonChain];

  if (!partnerAddress || isCBSubscribeDisabled) return null;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          close();

          // reset the previously mounted UI
          if (window.CBWSubscribe) {
            window.CBWSubscribe.subscriptionElement = undefined;
          }
        }}
      >
        <Box px={'md'}>
          <Title ta={'center'} size={'h3'} fw={'bold'}>
            {t('subscribeToMessagingModal.title', {
              chain: capitalize(moonChain),
            })}
          </Title>
          <Text py={'xl'}>
            {t('subscribeToMessagingModal.body', {
              chain: capitalize(moonChain),
            })}
          </Text>
          <Group justify={'right'}>
            {opened && <CBSubscribeButton partnerAddress={partnerAddress} />}
          </Group>
        </Box>
      </Modal>
      <ActionIcon
        className={classes.icon}
        size={52}
        variant={'filled'}
        onClick={open}
        title={t('subscribeToWalletMessaging')}
      >
        <TbMailPlus size={25} />
      </ActionIcon>
    </>
  );
};
