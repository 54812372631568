import { Box, BoxProps } from '@mantine/core';
import { Wallet } from 'lib/wallets';
import Image from 'next/image';
import { IoWallet } from 'react-icons/io5';
import { logos } from './logos';

export interface Props extends BoxProps {
  wallet: Wallet;
  size?: number;
}

export const WalletLogo = ({
  wallet,
  size = 24,
  className,
  ...others
}: Props) => {
  const logo = logos[wallet];

  return (
    <Box className={className} h={size} w={size} {...others}>
      {logo ? (
        <Image height={size} width={size} src={logo} alt={wallet} />
      ) : (
        <IoWallet size={size} />
      )}
    </Box>
  );
};
