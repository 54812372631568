import { Wallet } from 'lib/wallets';
import { StaticImageData } from 'next/image';

import coinbase from 'images/wallets/coinbase.svg';
import enkrypt from 'images/wallets/enkrypt.png';
import metamask from 'images/wallets/metamask.svg';
import nova from 'images/wallets/nova.png';
import polkadot from 'images/wallets/polkadot.svg';
import rabby from 'images/wallets/rabby.webp';
import subwallet from 'images/wallets/subwallet.svg';
import talisman from 'images/wallets/talisman.svg';
import walletconnect from 'images/wallets/walletconnect.png';

export const logos: Partial<Record<Wallet, StaticImageData>> = {
  [Wallet.Coinbase]: coinbase,
  [Wallet.Enkrypt]: enkrypt,
  [Wallet.Rabby]: rabby,
  [Wallet.MetaMask]: metamask,
  [Wallet.NovaWallet]: nova,
  [Wallet.Polkadot]: polkadot,
  [Wallet.SubWallet]: subwallet,
  [Wallet.Talisman]: talisman,
  [Wallet.WalletConnect]: walletconnect,
};
