import { MantineColorsTuple } from '@mantine/core';
import {
  moonbaseAlpha,
  moonbeam,
  moonriver,
} from '@moonbeam-network/xcm-config';
import { localDevConfig } from 'configs/chains';

// ! https://mantine.dev/colors-generator/?color=95F921
export const colors: Record<string, MantineColorsTuple> = {
  [moonbeam.key]: [
    '#e1fffd',
    '#cdfef8',
    '#9ffcf0',
    '#6cfae8',
    '#47f8e1',
    '#31f7dd',
    '#1ff7db',
    '#07d3ba',
    '#07d3ba',
    '#00aa94',
  ],
  [moonriver.key]: [
    '#f3ffe2',
    '#e7ffcc',
    '#d0fc9c',
    '#b6fb68',
    '#a1fa3b',
    '#95F921',
    '#8cf909',
    '#77dd00',
    '#68c500',
    '#56aa00',
  ],
  [moonbaseAlpha.key]: [
    '#fce7f1',
    '#fddede',
    '#fccece',
    '#fbbdbd',
    '#faadad',
    '#f89d9d',
    '#f78c8c',
    '#f67c7c',
    '#f56b6b',
    '#f45b5b',
  ],
  [localDevConfig.key]: [
    '#fce7f1',
    '#fddede',
    '#fccece',
    '#fbbdbd',
    '#faadad',
    '#f89d9d',
    '#f78c8c',
    '#f67c7c',
    '#f56b6b',
    '#f45b5b',
  ],
  moonPurple: [
    '#ebe8ee',
    '#d8d1de',
    '#c4bbcd',
    '#b1a4bd',
    '#9d8eac',
    '#8a779c',
    '#76608b',
    '#634a7b',
    '#4f336a',
    '#3d1d5a',
  ],
  moonDark: [
    '#ebe8ee',
    '#2d424d',
    '#2d424d',
    '#163737',
    '#163737',
    '#1f2334',
    '#1f2334',
    '#241631',
    '#241631',
    '#000000',
  ],
  balanceChartLight: [
    '#baeae9',
    '#53cbc9',
    '#e98f8f',
    '#e98f8f',
    '#e98f8f',
    '#e98f8f',
    '#e98f8f',
    '#e98f8f',
    '#e98f8f',
    '#e98f8f',
  ],
  balanceChartDark: [
    '#baeae9',
    '#53cbc9',
    '#f56b6b',
    '#f56b6b',
    '#f56b6b',
    '#f56b6b',
    '#f56b6b',
    '#f56b6b',
    '#f56b6b',
    '#f56b6b',
  ],
  moonriverBalanceChartLight: [
    '#baeae9',
    '#53cbc9',
    '#fdd520',
    '#fdd520',
    '#fdd520',
    '#fdd520',
    '#fdd520',
    '#fdd520',
    '#fdd520',
    '#fdd520',
  ],
  moonriverBalanceChartDark: [
    '#baeae9',
    '#53cbc9',
    '#fcc10e',
    '#fcc10e',
    '#fcc10e',
    '#fcc10e',
    '#fcc10e',
    '#fcc10e',
    '#fcc10e',
    '#fcc10e',
  ],
  referendaChart: [
    '#53cbc9',
    '#c9cce1',
    '#f4c346',
    '#5a4277',
    '#e1147b',
    '#42f593',
    '#c90a24',
    '#e4eb2a',
    '#d124e0',
    '#05691c',
  ],
};

export const ActionColors = {
  [moonbeam.key]: '#07D3BA',
  [moonriver.key]: '#95F921',
  [moonbaseAlpha.key]: '#76b4b3',
  [localDevConfig.key]: '#76b4b3',
};
