import { Button, Group, Modal } from '@mantine/core';
import { ActionButton } from 'components/ActionButton';
import { CookieConsentModalText } from 'components/CookieConsentModal/CookieConsentModalText';
import { useCookiesAccepted } from 'hooks/useCookiesAccepted';
import { useIsMounted } from 'hooks/useIsMounted';
import { useIsMobile } from 'hooks/useMedia';
import { useThemeColors } from 'hooks/useThemeColors';
import { useTranslation } from 'next-i18next';

export function CookieConsentModal() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const isMounted = useIsMounted();
  const { themeColors } = useThemeColors();

  const { isCookieAccepted, setCookieAccepted, setCookieRejected } =
    useCookiesAccepted();

  return (
    <Modal
      size={'xl'}
      padding={'xl'}
      opened={isMounted && isCookieAccepted === undefined}
      withCloseButton={false}
      onClose={() => undefined}
      yOffset={isMobile ? '15vh' : undefined}
      centered
    >
      <CookieConsentModalText />
      <Group mt={'xs'} justify={'flex-end'}>
        <>
          <Button
            style={{ color: themeColors.texts }}
            onClick={setCookieRejected}
          >
            {t('rejectAllCookies')}
          </Button>
          <ActionButton onClick={setCookieAccepted}>
            {t('acceptAllCookies')}
          </ActionButton>
        </>
      </Group>
    </Modal>
  );
}
