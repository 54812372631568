import {
  Box,
  Button,
  ButtonProps,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { isDarkScheme } from 'utils/theme';
import { Connector } from 'wagmi';
import classes from './WalletButton.module.css';

export interface Props extends ButtonProps {
  children: string;
  connector?: Connector;
  walletName: string;
  onClick: () => void;
}

export const WalletButton = ({
  children,
  connector,
  disabled,
  walletName,
  ...others
}: Props) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [isDisabled, setIsDisabled] = useState<boolean | undefined>(disabled);

  useEffect(() => {
    connector?.getProvider().then((provider) => setIsDisabled(!provider));
  }, [connector]);

  return (
    <Tooltip
      label={t('walletNotAvailable', { wallet: walletName })}
      disabled={!isDisabled}
    >
      <Box>
        <Button
          classNames={{
            root: classes.button,
            section: isDisabled ? classes.disabledIcon : classes.rightIcon,
            inner: classes.inner,
            label: classes.label,
          }}
          loaderProps={{
            color: isDarkScheme(colorScheme)
              ? theme.white
              : theme.colors.dark[5],
          }}
          disabled={isDisabled}
          variant={'light'}
          {...others}
        >
          {children}
        </Button>
      </Box>
    </Tooltip>
  );
};
