import {
  Divider,
  Group,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { OffsiteLink } from 'components/OffsiteLink';
import { useLatestBlock } from 'hooks/queries/useLatestBlock';
import { useApi } from 'hooks/useApi';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useThemeLogos } from 'hooks/useThemeLogos';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { IoEllipse } from 'react-icons/io5';
import { useIsTermsModalOpen } from 'recoil/isTermsModalOpen';
import classes from './Footer.module.css';

export const Footer = () => {
  const { t } = useTranslation();
  const moonChainKey = useMoonChainKeyParam();
  const theme = useMantineTheme();
  const api = useApi();
  const latestBlock = useLatestBlock();
  const { open } = useIsTermsModalOpen();
  const logo = useThemeLogos();

  return (
    <Group justify={'space-between'} className={classes.footer} h={29}>
      <Group pl={15} pt={3} gap={'xs'}>
        <Text size={'xs'}>{t('network')}</Text>
        <Image alt={moonChainKey} src={logo} width={69} height={14} />
        <Divider
          orientation={'vertical'}
          size={'md'}
          className={classes.divider}
        />
        <IoEllipse
          color={api ? theme.colors.moonbeam[9] : theme.colors.moonbeam[0]}
        />
        <Tooltip disabled={!api} label={`${t('latestBlock')} #${latestBlock}`}>
          <Text size={'xs'}>{t(api ? 'online' : 'connecting')}</Text>
        </Tooltip>
      </Group>
      <Group pr={15} pt={3} gap={'xs'}>
        <UnstyledButton className={classes.terms} onClick={open}>
          <Text size={'xs'}>{t('termsOfService')}</Text>
        </UnstyledButton>
        <OffsiteLink
          label={t('privacyPolicy')}
          link={'https://moonbeam.foundation/privacy-policy/'}
          size={'xs'}
        />

        <Divider
          orientation={'vertical'}
          size={'md'}
          className={classes.divider}
        />
        <Text size={'xs'}>{t('pricesByCoinGecko')}</Text>
      </Group>
    </Group>
  );
};
