import { useWagmiErrorHandler } from 'components/ConnectWallet/useWagmiErrorHandler';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { Wallet } from 'lib/wallets';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { Connector, useConnect } from 'wagmi';
import { WalletsPage } from './WalletsPage';
import { WalletInfo } from './interfaces';

interface Props {
  onClose: VoidFunction;
}

export function EvmWallets({ onClose }: Props) {
  const { t } = useTranslation('common', {
    keyPrefix: 'walletSetup.evm',
  });
  const onError = useWagmiErrorHandler();
  const { connect, connectors, isPending, isSuccess } = useConnect();
  const chain = useMoonChain();

  const getConnector = useCallback(
    ({ name }: WalletInfo) => {
      return connectors.find((c) => c.name === name);
    },
    [connectors],
  );

  const walletsInfo: WalletInfo[] = useMemo(() => {
    const baseInfo: WalletInfo[] = [
      {
        name: Wallet.MetaMask,
        primary: t('metaMask.primary'),
        secondary: t('metaMask.secondary'),
        link: {
          label: t('metaMask.link'),
          url: 'https://metamask.io',
        },
      },
      {
        name: Wallet.Talisman,
        primary: t('talisman.primary'),
        secondary: t('talisman.secondary'),
        link: {
          label: t('talisman.link'),
          url: 'https://www.talisman.xyz',
        },
      },
      {
        name: Wallet.SubWallet,
        primary: t('subWallet.primary'),
        secondary: t('subWallet.secondary'),
        link: {
          label: t('subWallet.link'),
          url: 'https://subwallet.app',
        },
      },
      {
        name: Wallet.Enkrypt,
        primary: t('enkrypt.primary'),
        secondary: t('enkrypt.secondary'),
        link: {
          label: t('enkrypt.link'),
          url: 'https://www.enkrypt.com',
        },
      },
      {
        name: Wallet.NovaWallet,
        primary: t('novaWallet.primary'),
        secondary: t('novaWallet.secondary'),
        link: {
          label: t('novaWallet.link'),
          url: 'https://novawallet.io',
        },
      },
      {
        name: Wallet.Coinbase,
        primary: t('coinbaseWallet.primary'),
        secondary: t('coinbaseWallet.secondary'),
        link: {
          label: t('coinbaseWallet.link'),
          url: 'https://www.coinbase.com/wallet',
        },
      },
      {
        name: Wallet.WalletConnect,
        version: '2',
        primary: t('walletConnect2.primary'),
        secondary: t('walletConnect2.secondary'),
        link: {
          label: t('walletConnect2.link'),
          url: 'https://walletconnect.com',
        },
      },
    ];

    return baseInfo.map((info) => ({
      ...info,
      isAvailable: !!getConnector(info),
    }));
  }, [t, getConnector]);

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess, onClose]);

  return (
    <WalletsPage
      isLoading={isPending}
      wallets={walletsInfo}
      onConnect={(walletInfo: WalletInfo) =>
        connect(
          {
            connector: getConnector(walletInfo) as Connector,
            chainId: chain.id,
          },
          { onError },
        )
      }
    />
  );
}
