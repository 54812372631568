import {
  Box,
  Grid,
  Group,
  Modal,
  Text,
  Title,
  UnstyledButton,
} from '@mantine/core';
import evmWalletsImage from 'images/walletSetup/evmWallets.png';
import substrateWalletsImage from 'images/walletSetup/substrateWallets.png';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useIsWalletSetupOpen } from 'recoil/isWalletSetupOpen';

import { ActionButton } from 'components/ActionButton';
import Image, { StaticImageData } from 'next/image';
import { IoArrowBackSharp } from 'react-icons/io5';
import { EvmWallets } from './EvmWallets';
import { SubstrateWallets } from './SubstrateWallets';
import classes from './WalletSetup.module.css';
import { Pages } from './interfaces';

interface WalletSection {
  title: string;
  image: StaticImageData;
  primary: string;
  secondary: string;
  highlight: boolean;
  action: VoidFunction;
}

export function WalletSetup() {
  const { t } = useTranslation('common', {
    keyPrefix: 'walletSetup',
  });
  const [page, setPage] = useState<Pages>(Pages.select);
  const { isOpen, close } = useIsWalletSetupOpen();

  const onClose = useCallback(() => {
    setPage(Pages.select);
    close();
  }, [close]);

  const wallets = useMemo<WalletSection[]>(
    () => [
      {
        title: t('evm.title'),
        primary: t('evm.primary'),
        secondary: t('evm.secondary'),
        highlight: true,
        image: evmWalletsImage,
        action: () => setPage(Pages.evm),
      },
      {
        title: t('substrate.title'),
        primary: t('substrate.primary'),
        secondary: t('substrate.secondary'),
        highlight: false,
        image: substrateWalletsImage,
        action: () => setPage(Pages.substrate),
      },
    ],
    [t],
  );

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={t('walletSetup')}
      centered
      size={'lg'}
      padding={0}
      zIndex={75}
      classNames={{
        header: classes.header,
        title: classes.title,
      }}
    >
      {page === Pages.select ? (
        wallets.map((wallet, i) => (
          <Grid key={i} p={30} m={0} className={classes.box}>
            <Grid.Col span={{ sm: 5, md: 5 }} ta={'center'} p={0} pr={25}>
              <Image width={200} src={wallet.image} alt={wallet.title} />
              <Title fw={'normal'} order={2} mt={'xs'}>
                {wallet.title}
              </Title>
            </Grid.Col>
            <Grid.Col span={{ sm: 7, md: 7 }} p={0} pl={25}>
              <Text>{wallet.primary}</Text>
              <Text mt={'md'} fw={wallet.highlight ? 'bold' : 'normal'}>
                {wallet.secondary}
              </Text>
              <Group mt={'md'} justify={'right'}>
                <ActionButton onClick={wallet.action}>
                  {t('setup')}
                </ActionButton>
              </Group>
            </Grid.Col>
          </Grid>
        ))
      ) : (
        <Box p={'xl'} pt={0}>
          <UnstyledButton onClick={() => setPage(Pages.select)} mb={'md'}>
            <IoArrowBackSharp /> {t('pageBack')}
          </UnstyledButton>

          {page === Pages.evm && <EvmWallets onClose={onClose} />}
          {page === Pages.substrate && <SubstrateWallets onClose={onClose} />}
        </Box>
      )}
    </Modal>
  );
}
