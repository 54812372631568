import { Text } from '@mantine/core';
import { OffsiteLink } from 'components/OffsiteLink';

export function CookieConsentModalText() {
  return (
    <Text mb={'xs'}>
      {
        'We use cookies to enhance your browsing experience, personalize content, and analyze our traffic. By clicking "Accept All Cookies", you agree to the storing of cookies on your device. For more information, please see our '
      }
      <OffsiteLink
        link={'https://moonbeam.foundation/privacy-policy'}
        label={'Privacy Policy'}
        size={'md'}
      />
    </Text>
  );
}
