// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseJson(str: unknown): Record<string, any> {
  if (typeof str !== 'string') {
    return {};
  }

  try {
    const result = JSON.parse(str);

    return typeof result === 'object' && result !== null ? result : {};
  } catch (error) {
    console.error(error);

    return {};
  }
}

function replacer(key: string, value: unknown): string | unknown {
  if (typeof value === 'bigint') {
    return value.toString() + 'n';
  }

  return value;
}

function reviver(key: string, value: unknown): bigint | unknown {
  if (typeof value === 'string' && /^\d+n$/.test(value)) {
    return BigInt(value.slice(0, -1));
  }

  return value;
}

export function jsonStringifyWithBigInt(obj: unknown, pretty = false): string {
  if (pretty) {
    return JSON.stringify(obj, replacer, 2);
  }

  return JSON.stringify(obj, replacer);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function jsonParseWithBigInt(jsonStr: string): any {
  return JSON.parse(jsonStr, reviver);
}
