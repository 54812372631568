import { Box, Group, Paper, Text, Title, Tooltip } from '@mantine/core';
import { MoonChainButton } from 'components/MoonChainButton';
import {
  moonbaseConfig,
  moonbeamConfig,
  moonriverConfig,
} from 'configs/chains';
import { useIsMobile } from 'hooks/useMedia';
import { useTranslation } from 'next-i18next';
import { IoInformationCircleOutline } from 'react-icons/io5';
import classes from './MoonChainSection.module.css';

export const MoonChainSection = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Paper px={'lg'} pb={'sm'} className={classes.chainPaper}>
      <Title order={4} display={'inline-block'}>
        {t('networks')}
      </Title>
      <Tooltip
        label={
          <>
            <Text>{t('networkTooltip1')}</Text>
            <Text>{t('networkTooltip2')}</Text>
          </>
        }
        withArrow
      >
        <Box ml={'xs'} display={'inline-block'}>
          <IoInformationCircleOutline />
        </Box>
      </Tooltip>
      <Group my={'md'} gap={'md'} justify={isMobile ? 'center' : undefined}>
        {[moonbeamConfig, moonriverConfig, moonbaseConfig].map((config) => (
          <MoonChainButton
            key={config.key}
            classNames={{ root: classes.root }}
            chain={config}
          />
        ))}
      </Group>
    </Paper>
  );
};
