import {
  ActionIcon,
  Combobox,
  Group,
  InputBase,
  Modal,
  NumberInput,
  Switch,
  Text,
  useCombobox,
} from '@mantine/core';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useMemo, useState } from 'react';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { useUserSettings } from 'recoil/userSettings';
import { SMALL_BALANCE_FILTERING_VALUE } from 'recoil/userSettings/atoms';
import { FiatCurrencies } from 'types/fiatCurrencies';
import { getFiatImage } from 'utils/images';

export function UserSettingsModal() {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const {
    areBalancesVisible,
    fiatCurrency,
    smallBalanceFilteringValue,
    hideBalances,
    showBalances,
    setFiatCurrency,
    setBalanceFilteringValue,
  } = useUserSettings();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const data = useMemo(
    () =>
      Object.values(FiatCurrencies).map((value) => ({
        value,
        image: getFiatImage(value),
        label: value,
      })),
    [],
  );

  const options = useMemo(
    () =>
      data.map(({ value, image, label }) => (
        <Combobox.Option
          value={value}
          key={value}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <Group wrap={'nowrap'}>
            <Image width={30} src={image} alt={label} />
            <Text size={'sm'} tt={'uppercase'}>
              {label}
            </Text>
          </Group>
        </Combobox.Option>
      )),
    [data],
  );

  return (
    <>
      <Modal
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Group gap={'xs'}>
            <HiOutlineWrenchScrewdriver size={20} />
            {t('settings')}
          </Group>
        }
      >
        <Combobox
          store={combobox}
          onOptionSubmit={(value) => {
            combobox.toggleDropdown();
            setFiatCurrency(value as FiatCurrencies);
          }}
        >
          <Combobox.Target>
            <InputBase
              label={t('fiatCurrency')}
              styles={{
                input: {
                  textTransform: 'uppercase',
                },
              }}
              component={'button'}
              type={'button'}
              pointer
              value={fiatCurrency}
              leftSection={
                <Image
                  width={25}
                  src={getFiatImage(fiatCurrency)}
                  alt={fiatCurrency}
                />
              }
              rightSection={<Combobox.Chevron />}
              onClick={() => combobox.toggleDropdown()}
              rightSectionPointerEvents={'none'}
            >
              {fiatCurrency}
            </InputBase>
          </Combobox.Target>
          <Combobox.Dropdown>
            <Combobox.Options>{options}</Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
        <Text pt={'lg'} size={'sm'}>
          {t('hideBalances')}
        </Text>
        <Switch
          pt={2}
          label={t(areBalancesVisible ? 'no' : 'yes')}
          checked={!areBalancesVisible}
          onChange={() =>
            areBalancesVisible ? hideBalances() : showBalances()
          }
        />
        <NumberInput
          pt={'lg'}
          label={`${t(
            'smallBalanceFilteringValue',
          )} (${fiatCurrency.toUpperCase()})`}
          value={smallBalanceFilteringValue ?? SMALL_BALANCE_FILTERING_VALUE}
          onChange={setBalanceFilteringValue}
        />
      </Modal>

      <ActionIcon onClick={() => setOpened(true)} title={t('icons.settings')}>
        <HiOutlineWrenchScrewdriver size={20} />
      </ActionIcon>
    </>
  );
}
