import { AppShell, Center, Container } from '@mantine/core';
import { OpenMenuButton } from 'components/AppShell/OpenMenuButton';
import { useIsMobile } from 'hooks/useMedia';
import classes from './Navbar.module.css';
import { NavbarLogo } from './NavbarLogo';
import { NavbarMenu } from './NavbarMenu';
import { NavbarSettings } from './NavbarSettings';

export const Navbar = () => {
  const isMobile = useIsMobile();

  return (
    <AppShell.Navbar zIndex={10} withBorder={false} className={classes.navbar}>
      <AppShell.Section>
        <Container pt={isMobile ? 'md' : 0}>
          <OpenMenuButton />
          <Center>
            <NavbarLogo />
          </Center>
        </Container>
      </AppShell.Section>
      <AppShell.Section grow={!isMobile} className={classes.scrollable}>
        <NavbarMenu />
      </AppShell.Section>
      <AppShell.Section>
        <NavbarSettings />
      </AppShell.Section>
    </AppShell.Navbar>
  );
};
