import { ActionIcon, Container, Divider, Group } from '@mantine/core';
import { OffsiteLink } from 'components/OffsiteLink';
import { useTranslation } from 'next-i18next';
import { IoLogoGithub } from 'react-icons/io5';
import { DarkModeToggle } from './DarkModeToggle';
import { UserSettingsModal } from './UserSettingsModal';

export const NavbarSettings = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Divider my={'sm'} />
      <Group justify={'center'} pb={16} px={8}>
        <OffsiteLink
          link={'https://github.com/moonbeam-foundation/moonbeam'}
          aria-label={t('icons.github')}
          withIcon={false}
        >
          <ActionIcon title={t('icons.github')}>
            <IoLogoGithub size={20} />
          </ActionIcon>
        </OffsiteLink>
        <UserSettingsModal />
        <DarkModeToggle />
      </Group>
    </Container>
  );
};
