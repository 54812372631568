import { moonbaseAlpha, moonriver } from '@moonbeam-network/xcm-config';
import { useIsAppDirectoryDisabledFlag } from 'hooks/flags/useIsAppDirectoryDisabledFlag';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';

export interface OffsiteLinks {
  alerts: string | null;
  bridges?: string;
  collators: string;
  ecosystem?: string;
  explorers?: string;
  orbiter: string;
  requests: string;
  signalVoting: string;
  discussions: string;
}

export function useOffsiteLinks(): OffsiteLinks {
  const moonChainKey = useMoonChainKeyParam();
  const stakeGlmrUrl = 'https://stakeglmr.com/';
  const stakeMovrUrl = 'https://stakemovr.com/';
  const isAppDirDisabled = useIsAppDirectoryDisabledFlag();

  let alerts = null;
  let collators = stakeGlmrUrl;

  if (moonChainKey !== moonbaseAlpha.key) {
    alerts = `https://web3alert.io/${moonChainKey}`;
  }

  if (moonChainKey === moonriver.key) {
    collators = stakeMovrUrl;
  }

  return {
    alerts,
    bridges: isAppDirDisabled ? undefined : `app-dir?cat=bridges`,
    collators: collators,
    ecosystem: isAppDirDisabled ? undefined : 'app-dir',
    requests: 'https://request.moonbeam.network/',
    orbiter:
      'https://docs.moonbeam.network/node-operators/networks/collators/orbiter/',
    signalVoting: 'https://snapshot.org/#/moonbeam-foundation.eth',
    discussions: 'https://forum.moonbeam.foundation/',
  };
}
