import { Container } from '@mantine/core';
import { MenuItemProps } from 'components/AppShell/Navbar/NavbarMenu/MenuItem';
import { useIsAppDirectoryDisabledFlag } from 'hooks/flags/useIsAppDirectoryDisabledFlag';
import { useIsGovV1 } from 'hooks/flags/useIsGovV1';
import { useIsMrlDisabledFlag } from 'hooks/flags/useIsMrlDisabledFlag';
import { useIsProxyPageDisabledFlag } from 'hooks/flags/useIsProxyPageDisabledFlag';
import { useTracks } from 'hooks/queries/useTracks';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useOffsiteLinks } from 'hooks/useOffsiteLinks';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { BiCoinStack, BiTransfer } from 'react-icons/bi';
import { BsGlobe2 } from 'react-icons/bs';
import { HiOutlineTemplate } from 'react-icons/hi';
import { MdOutlineHowToVote } from 'react-icons/md';
import { SiEnvoyproxy } from 'react-icons/si';
import { TbPlant, TbSmartHome } from 'react-icons/tb';
import { getReferendaTracksIconOrDefault } from 'utils/theme';
import { MenuCategory, MenuCategoryProps } from './MenuCategory';

export const mrlPath = 'mrl';
export const xcmPath = 'xcm';

const appDirItems = [
  {
    label: 'appDirCategories.overview',
    path: `app-dir`,
  },
  {
    label: 'appDirCategories.defi',
    path: `app-dir?cat=defi`,
  },
  {
    label: 'appDirCategories.dex',
    path: `app-dir?cat=dex`,
  },
  {
    label: 'appDirCategories.bridges',
    path: `app-dir?cat=bridges`,
  },
  {
    label: 'appDirCategories.lending',
    path: `app-dir?cat=lending`,
  },
  {
    label: 'appDirCategories.nfts',
    path: `app-dir?cat=nfts`,
  },
  {
    label: 'appDirCategories.gaming',
    path: `app-dir?cat=gaming`,
  },
  {
    label: 'appDirCategories.social',
    path: `app-dir?cat=social`,
  },
  {
    label: 'appDirCategories.wallets',
    path: `app-dir?cat=wallets`,
  },
  {
    label: 'appDirCategories.dao',
    path: `app-dir?cat=dao`,
  },
  {
    label: 'appDirCategories.other',
    path: `app-dir?cat=other`,
  },
] satisfies MenuItemProps[];

export const NavbarMenu = () => {
  const { t } = useTranslation();
  const {
    bridges,
    collators,
    ecosystem,
    explorers,
    orbiter,
    requests,
    alerts,
    signalVoting,
    discussions,
  } = useOffsiteLinks();
  const { polkassemblyOpenGovUrl } = useMoonChain();

  const openGovTracks = useTracks();
  const isGovV1 = useIsGovV1();
  const isAppDirDisabled = useIsAppDirectoryDisabledFlag();
  const isMrlDisabled = useIsMrlDisabledFlag();
  const isProxyPageDisabled = useIsProxyPageDisabledFlag();

  const stakingItems: MenuItemProps[] = useMemo(() => {
    return [
      { label: 'overview', path: 'staking' },
      { label: 'collators', link: collators },
      { label: 'orbiterProgram', link: orbiter },
    ];
  }, [collators, orbiter]);

  const crossChainItems: MenuItemProps[] = useMemo(() => {
    const items = [
      { label: 'parachainBridges', path: xcmPath },
      ...(bridges ? [{ label: 'otherBridges', link: bridges }] : []),
    ];

    if (!isMrlDisabled) {
      // push as first item
      items.unshift({ label: 'externalBridges', path: mrlPath });
    }

    return items;
  }, [bridges, isMrlDisabled]);

  const governanceLinkItems: MenuItemProps[] = useMemo(() => {
    return [
      {
        label: 'signalVoting',
        link: signalVoting,
        tooltip: t('signalVotingTooltip'),
      },
      { label: 'discussions', link: discussions },
      ...(alerts ? [{ label: 'alerts', link: alerts }] : []),
    ];
  }, [alerts, discussions, signalVoting, t]);

  const govV1Items: MenuItemProps[] = useMemo(() => {
    return [
      { label: 'overview', path: 'governance' },
      ...(polkassemblyOpenGovUrl
        ? [{ label: 'openGov', link: polkassemblyOpenGovUrl }]
        : []),
    ];
  }, [polkassemblyOpenGovUrl]);

  const openGovItems: MenuItemProps[] = useMemo(() => {
    const govItems: MenuItemProps[] = [{ label: 'overview', path: 'open-gov' }];

    openGovTracks?.forEach((track) => {
      govItems.push({
        label: `governanceTracks.${track.name}`,
        path: `open-gov?track=${track.name}`,
        icon: getReferendaTracksIconOrDefault(track.name),
      });
    });

    return govItems;
  }, [openGovTracks]);

  const governanceItems: MenuItemProps[] = useMemo(() => {
    return [...(isGovV1 ? govV1Items : openGovItems), ...governanceLinkItems];
  }, [govV1Items, governanceLinkItems, isGovV1, openGovItems]);

  const othersItems: MenuItemProps[] = useMemo(() => {
    return [
      ...(ecosystem ? [{ label: 'ecosystem', link: ecosystem }] : []),
      ...(explorers ? [{ label: 'chainExplorers', link: explorers }] : []),
      { label: 'requests', link: requests },
    ];
  }, [ecosystem, explorers, requests]);

  const itemsByCategory: MenuCategoryProps[] = useMemo(() => {
    let memoItems = [
      {
        label: 'home',
        path: '',
        icon: <TbSmartHome />,
      },
      {
        label: 'staking',
        items: stakingItems,
        icon: <TbPlant />,
      },
      {
        label: 'crossChain',
        items: crossChainItems,
        icon: <BiTransfer />,
      },
      {
        label: 'governance',
        items: governanceItems,
        icon: <MdOutlineHowToVote />,
      },
      {
        label: 'proxy',
        path: 'proxy',
        icon: <SiEnvoyproxy />,
      },
      ...(!isAppDirDisabled
        ? [
            {
              label: 'projects',
              path: 'app-dir',
              items: appDirItems,
              icon: <BsGlobe2 />,
            },
          ]
        : []),
      {
        label: 'crowdloanRewards',
        path: 'crowdloan-rewards',
        icon: <BiCoinStack />,
      },
      {
        label: 'others',
        items: othersItems,
        icon: <HiOutlineTemplate />,
      },
    ];

    // find proxy in array and remove it if disabled
    if (isProxyPageDisabled) {
      memoItems = memoItems.filter((item) => item.path !== 'proxy');
    }

    return memoItems;
  }, [
    crossChainItems,
    governanceItems,
    isAppDirDisabled,
    isProxyPageDisabled,
    othersItems,
    stakingItems,
  ]);

  return (
    <Container px={26}>
      {itemsByCategory.map((category) => (
        <MenuCategory {...category} key={category.label}></MenuCategory>
      ))}
    </Container>
  );
};
