import { Button, ButtonProps } from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/utils';
import cx from 'clsx';
import classes from './ActionButton.module.css';

export type Props<C = 'button'> = PolymorphicComponentProps<C, ButtonProps> & {
  mRef?: React.Ref<HTMLButtonElement> | null;
};

export const ActionButton = <C = 'button',>({
  className,
  classNames,
  styles,
  mRef,
  variant,
  ...other
}: Props<C>) => {
  return (
    //@ts-expect-error: FIXME: ButtonProps is not compatible with Props<C>
    <Button
      ref={mRef}
      className={cx(
        variant === 'outline' ? classes.buttonOutline : classes.button,
        className,
      )}
      classNames={classNames}
      styles={styles}
      variant={variant}
      {...other}
    />
  );
};
