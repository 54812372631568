import { Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { Avatar } from 'components/Avatar';
import { useAddress } from 'hooks/useAddress';
import { useThemeColors } from 'hooks/useThemeColors';
import { useTranslation } from 'next-i18next';
import { useSubstrateExtension } from 'recoil/substrateExtension';
import { sliceEllipsis } from 'utils/text';
import { useDisconnect } from 'wagmi';

export const ConnectedAvatar = () => {
  const { t } = useTranslation();
  const address = useAddress();
  const { disconnect } = useDisconnect();
  const { setSelectedExtension } = useSubstrateExtension();
  const { themeColors } = useThemeColors();

  return (
    <Group
      align={'center'}
      style={(theme) => ({
        height: 52,
        padding: theme.spacing.xs,
        backgroundColor: themeColors.whiteDark,
        borderRadius: theme.radius.md,
      })}
    >
      <Avatar address={address} size={26} />
      <Stack gap={4}>
        <Text size={'10px'} fw={'bold'}>
          {sliceEllipsis(address, 8, 6)}
        </Text>
        <UnstyledButton
          aria-label={t('disconnect')}
          onClick={() => {
            disconnect();
            setSelectedExtension(undefined);
          }}
        >
          <Text size={'10px'} c={themeColors.secondaryTexts}>
            {t('disconnect')}
          </Text>
        </UnstyledButton>
      </Stack>
    </Group>
  );
};
