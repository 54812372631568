import { internalUrls } from 'configs/urls';

export function isTrustedUrl(url: string): boolean {
  return internalUrls.some((u) => url.includes(u));
}

export function getUrlDomainFromString(url: string): string {
  try {
    const urlObj = new URL(url);

    return urlObj.hostname;
  } catch (error) {
    console.error(error);

    return '';
  }
}
