import { NextRouter } from 'next/router';

export const buildFullPath = (moonChainKey: string, path?: string): string => {
  return `/${moonChainKey}/${path ?? ''}`.replace(/\/+$/, '');
};

export const checkCurrentPath = (
  router: NextRouter,
  fullPath: string,
  path?: string,
): boolean => {
  return path != undefined && router.asPath == fullPath;
};

export const getChangeMoonChainPath = (pathname: string): string => {
  return pathOverwriteMap[pathname] ?? pathname;
};

const pathOverwriteMap: Record<string, string> = {
  '/[moonChainKey]/referendum/[id]': '/[moonChainKey]/open-gov',
  '/[moonChainKey]/app-dir/[id]': '/[moonChainKey]/app-dir',
  '/[moonChainKey]/mrl': '/[moonChainKey]',
};
