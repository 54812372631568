import {
  agng,
  dev,
  moonbaseAlpha,
  peaqAlphanet,
  peaqEvmAlphanet,
} from '@moonbeam-network/xcm-config';
import { peaqEvmAlphanetViemChain } from 'providers/customChains';
import { MrlEvmParachain, MrlParachain } from 'services/mrl/chains/classes';
import { Ecosystem, MrlChainType } from 'services/mrl/chains/classes/BaseChain';
import {
  mrlTestnetAgng,
  mrlTestnetFtmWH,
} from 'services/mrl/tokens/chainTokens/testnet/parachainTestnetTokens';
import { Address } from 'viem';
import { fantomTestnet } from 'viem/chains';

export const peaqAlphanetMrlChain = new MrlParachain({
  key: peaqAlphanet.key,
  name: peaqAlphanet.name,
  type: MrlChainType.Parachain,
  parachainId: peaqAlphanet.parachainId,
  genesisHash: peaqAlphanet.genesisHash,
  isTestChain: peaqAlphanet.isTestChain,
  isAutomaticPossible: false,
  ss58Format: peaqAlphanet.ss58Format,
  ws: peaqAlphanet.ws,
  explorer: `https://polkadot.js.org/apps/?rpc=${encodeURIComponent(Array.isArray(peaqAlphanet.ws) ? peaqAlphanet.ws[0] : peaqAlphanet.ws)}#/explorer/query`,
  ecosystem: Ecosystem.Dotsama,
  redeemChainId: moonbaseAlpha.id,
  redeemChainName: moonbaseAlpha.name,
  destinations: [
    {
      chain: fantomTestnet.name,
      tokens: [mrlTestnetFtmWH, mrlTestnetAgng],
      fees: [{ asset: dev, min: 0.1 }],
    },
  ],
  transferrableAssets: [
    {
      token: mrlTestnetFtmWH,
      destination: fantomTestnet.name,
      fees: [{ asset: dev, min: 0.1 }],
      destinationFee: 0.0001,
    },
    {
      token: mrlTestnetAgng,
      destination: fantomTestnet.name,
      fees: [{ asset: agng, min: 0.2 }],
      // TODO technically not correct, it's not destination fee but fees in Moonbase,
      // but we don't yet have a way to separate fee assets in the field `fees`
      // fix this with the new interface we use to migrate to the sdk
      destinationFee: 0.2,
    },
  ],
});

const peaqEvmWFTM = {
  ...mrlTestnetFtmWH,
  address: '0xFffFffFF000000000000000000000000000003E9' as Address,
};

const devAddress = '0xFfFfFffF000000000000000000000000000003e8' as Address;

export const peaqEvmAlphanetMrlChain = new MrlEvmParachain({
  key: peaqEvmAlphanet.key,
  name: peaqEvmAlphanet.name,
  parachainId: peaqEvmAlphanet.parachainId,
  genesisHash: peaqEvmAlphanet.genesisHash,
  chainId: peaqEvmAlphanet.id,
  isTestChain: peaqEvmAlphanet.isTestChain,
  isAutomaticPossible: false,
  ss58Format: peaqEvmAlphanet.ss58Format,
  ws: peaqEvmAlphanet.ws,
  explorer: `https://polkadot.js.org/apps/?rpc=${encodeURIComponent(Array.isArray(peaqEvmAlphanet.ws) ? peaqEvmAlphanet.ws[0] : peaqEvmAlphanet.ws)}#/explorer/query`,
  ecosystem: Ecosystem.Dotsama,
  redeemChainId: moonbaseAlpha.id,
  redeemChainName: moonbaseAlpha.name,
  viemChain: peaqEvmAlphanetViemChain,
  moonTokenAddress: devAddress, // ? Not sure if good idea to have it here, It's for DEV/GLMR on the Evm Parachain
  destinations: [
    {
      chain: fantomTestnet.name,
      tokens: [peaqEvmWFTM],
      fees: [{ asset: dev, min: 0.1 }],
    },
  ],
  transferrableAssets: [
    {
      token: peaqEvmWFTM,
      destination: fantomTestnet.name,
      fees: [{ asset: dev, min: 0.1 }],
      destinationFee: 0.0001,
    },
  ],
});
