/* eslint-disable @typescript-eslint/no-explicit-any */
// import { ConnectArgs } from '@wagmi/core';
import {
  TransactionAction,
  useWalletNotifications,
} from 'hooks/notifications/useWalletNotifications';
import { useCallback } from 'react';

/**
 * Wagmi errors you can find here
 * https://github.com/wevm/wagmi/blob/main/packages/core/src/errors
 */
export function useWagmiErrorHandler(): (error: Error, args: any) => void {
  const { notifyTransactionError } = useWalletNotifications(
    TransactionAction.CONNECTION,
  );

  return useCallback(
    (error: Error, args: any) => {
      notifyTransactionError(error, args);
    },
    [notifyTransactionError],
  );
}
