import { Group, Stack } from '@mantine/core';
import cx from 'clsx';
import { useWagmiErrorHandler } from 'components/ConnectWallet/useWagmiErrorHandler';
import { EvmAccount } from 'components/EvmAccount';
import { WalletLogo } from 'components/WalletLogo';
import { useMoonBalances } from 'hooks/queries/balances/useMoonChainBalances';
import { useAddress } from 'hooks/useAddress';
import { useIsMobile, useIsTablet } from 'hooks/useMedia';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useMoonChainToDecimals } from 'hooks/useToDecimal';
import { Wallet, mobileWallets } from 'lib/wallets';
import { useIsConnectWalletOpen } from 'recoil/isConnectWalletOpen';
import { delay } from 'utils/delay';
import { sliceEllipsis } from 'utils/text';
import { isCoinbaseConnector } from 'utils/wagmi';
import { Connector, useAccount, useConnect, useDisconnect } from 'wagmi';
import { WalletButton } from './WalletButton';
import classes from './WalletButton.module.css';

function isSameConnector(
  connector1: Connector | undefined,
  connector2: Connector | undefined,
): boolean {
  if (!connector1 || !connector2) {
    return false;
  }

  return (
    connector1?.id === connector2.id && connector1?.name === connector2.name
  );
}

interface CoinbaseWalletError extends Error {
  code?: number;
}

export const EvmSection = () => {
  const chain = useMoonChain();
  const address = useAddress();
  const { connector: selectedConnector } = useAccount();
  const { disconnect } = useDisconnect();
  const balances = useMoonBalances();
  const onError = useWagmiErrorHandler();
  const { connectors, connect, isPending } = useConnect();

  const isMobile = useIsMobile();
  const { close } = useIsConnectWalletOpen();
  const isTablet = useIsTablet();
  const displayBalance = `${useMoonChainToDecimals(balances?.free)} ${
    chain.asset.originSymbol
  }`;

  const filteredConnectors = connectors.filter(
    ({ name, type, id }) =>
      // remove these injected connectors to avoid duplication
      !(name === Wallet.Coinbase && type === 'injected') &&
      id !== 'io.metamask',
  );

  return (
    <Stack p={'lg'} justify={'space-between'} mih={360}>
      <Group justify={isMobile ? 'center' : undefined}>
        {filteredConnectors.map((connector, index) => {
          const isWC = connector.name === Wallet.WalletConnect;

          if (isTablet && !mobileWallets.includes(connector.name as Wallet)) {
            return null;
          }

          const onClick = () => {
            const connectConnector = () =>
              connect(
                { connector, chainId: chain.id },
                {
                  onSuccess() {
                    close();

                    if (isWC) {
                      // ! Workaround for WalletConnect to switch chain after connection
                      connector.getChainId().then((chainId) => {
                        if (chainId !== chain.id) {
                          connector?.switchChain?.({ chainId: chain.id });
                        }
                      });
                    }
                  },
                  onError(error, { connector, chainId }) {
                    /**
                     * Workaround for connection in first try for Coinbase Wallet
                     */
                    if (
                      isCoinbaseConnector(connector as Connector) &&
                      (error as CoinbaseWalletError).code === 4902
                    ) {
                      connect({ connector, chainId });
                    } else {
                      onError(error, { connector, chainId });
                    }
                  },
                },
              );

            if (isSameConnector(selectedConnector, connector)) {
              return;
            }

            if (selectedConnector?.id === connector.id && !isWC) {
              disconnect();
              delay(200).then(connectConnector);

              return;
            }

            connectConnector();
          };

          return (
            <WalletButton
              key={connector.name + index}
              connector={connector}
              className={cx({
                [classes.selected]: isSameConnector(
                  selectedConnector,
                  connector,
                ),
              })}
              leftSection={<WalletLogo wallet={connector.name as Wallet} />}
              loading={isPending}
              walletName={connector.name}
              onClick={onClick}
              data-testid={`wallet-${connector.name}-button`}
            >
              {connector.name}
            </WalletButton>
          );
        })}
      </Group>
      <EvmAccount
        mt={'md'}
        rightIcon={balances?.free ? displayBalance : undefined}
      >
        {isMobile ? sliceEllipsis(address, 5, 2) : sliceEllipsis(address)}
      </EvmAccount>
    </Stack>
  );
};
