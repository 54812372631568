import { SetOptional } from 'type-fest';
import {
  Ecosystem,
  MrlBaseChain,
  MrlChainType,
  MrlTransferrableParachainAssets,
  type BaseChainConstructorParams,
  type MrlParachainDestination,
} from './BaseChain';

export interface ParachainConstructorParams
  extends SetOptional<
    BaseChainConstructorParams,
    'type' | 'ecosystem' | 'isEvm' | 'whName'
  > {
  destinations: MrlParachainDestination[];
  transferrableAssets: MrlTransferrableParachainAssets[];
  ws: string | string[];
  parachainId: number;
}

export class MrlParachain extends MrlBaseChain {
  readonly destinations: MrlParachainDestination[];
  readonly transferrableAssets: MrlTransferrableParachainAssets[];
  readonly ws: string | string[];
  readonly parachainId: number;

  constructor({
    destinations,
    transferrableAssets,
    ws,
    parachainId,
    type = MrlChainType.Parachain,
    ecosystem = Ecosystem.Dotsama,
    whName = 'Moonbeam',
    isEvm = false,
    ...others
  }: ParachainConstructorParams) {
    super({ ...others, type, isEvm, ecosystem, whName });
    this.destinations = destinations;
    this.transferrableAssets = transferrableAssets;
    this.ws = ws;
    this.parachainId = parachainId;
  }

  useSubstrateWallet(): boolean {
    return true;
  }
}
