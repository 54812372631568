import { ActionIcon, Group, Stack } from '@mantine/core';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useThemeLogos } from 'hooks/useThemeLogos';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { IoSwapHorizontalSharp } from 'react-icons/io5';
import { useRecoilState } from 'recoil';
import { isMoonChainSelectionOpenAtom } from 'recoil/isMoonChainSelectionOpen';

export const NavbarLogo = () => {
  const { t } = useTranslation();
  const moonChainKey = useMoonChainKeyParam();
  const [, setIsMoonChainModalOpen] = useRecoilState(
    isMoonChainSelectionOpenAtom,
  );
  const logo = useThemeLogos();

  return (
    <Stack
      style={{
        height: 65,
        marginTop: 32,
      }}
      gap={0}
    >
      <Image
        src={logo}
        alt={moonChainKey}
        width={200}
        height={45}
        style={{ objectFit: 'contain' }}
        priority
      />
      <Group justify={'right'}>
        <ActionIcon
          title={t('icons.changeChain')}
          onClick={() => setIsMoonChainModalOpen(true)}
        >
          <IoSwapHorizontalSharp />
        </ActionIcon>
      </Group>
    </Stack>
  );
};
