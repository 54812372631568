import { Button, Group, Modal } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { ActionButton } from 'components/ActionButton';
import { TermsText } from 'components/TermsModal/TermsText';
import { useIsMounted } from 'hooks/useIsMounted';
import { useIsMobile } from 'hooks/useMedia';
import { useThemeColors } from 'hooks/useThemeColors';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import { useIsTermsModalOpen } from 'recoil/isTermsModalOpen';

export function TermsModal() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [termsAcceptedUtil, setTermsAcceptedUtil] = useLocalStorage<
    string | undefined
  >({
    key: 'terms_accepted_util',
  });
  const { isOpen, close, open } = useIsTermsModalOpen();
  const isMounted = useIsMounted();
  const { themeColors } = useThemeColors();

  const areTermsAccepted = useMemo(
    () =>
      termsAcceptedUtil ? new Date(termsAcceptedUtil) > new Date() : false,
    [termsAcceptedUtil],
  );

  useEffect(() => {
    if (isMounted && !areTermsAccepted) open();
  }, [isMounted, areTermsAccepted, open]);

  const acceptTerms = () => {
    const date = new Date();

    date.setMonth(date.getMonth() + 3);
    setTermsAcceptedUtil(date.toISOString());
    close();
  };

  return (
    <Modal
      size={'xl'}
      padding={'xl'}
      opened={isOpen}
      withCloseButton={false}
      onClose={() => undefined}
      yOffset={isMobile ? '15vh' : undefined}
    >
      <TermsText />
      <Group mt={'xs'} justify={'flex-end'}>
        {areTermsAccepted ? (
          <ActionButton onClick={close}>{t('close')}</ActionButton>
        ) : (
          <>
            <Button
              style={{ color: themeColors.texts }}
              component={'a'}
              href={'https://moonbeam.foundation/'}
            >
              {t('decline')}
            </Button>
            <ActionButton onClick={acceptTerms}>{t('accept')}</ActionButton>
          </>
        )}
      </Group>
    </Modal>
  );
}
