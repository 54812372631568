import { Button, ButtonProps, Text } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { IoWallet } from 'react-icons/io5';
import { useIsConnectWalletOpen } from 'recoil/isConnectWalletOpen';
import classes from './ConnectEvmWallet.module.css';

export const ConnectEvmWallet = ({ ...others }: ButtonProps) => {
  const { t } = useTranslation();
  const { openEvm } = useIsConnectWalletOpen();

  return (
    <Button
      size={'compact-md'}
      styles={{ root: { height: 32 } }}
      onClick={openEvm}
      leftSection={<IoWallet className={classes.color} />}
      {...others}
    >
      <Text className={classes.color}>{t('connectWallet')}</Text>
    </Button>
  );
};
